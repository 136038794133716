var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"91c80746df57e754ea2c0a5389f661842cb2a897"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const sentryDsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development';
// eslint-disable-next-line no-process-env
const release = process.env.RENDER_GIT_COMMIT;

Sentry.init({
  dsn:
    sentryDsn ||
    'https://5a942e7aff2c4ad5b9640477197abc4c@o242872.ingest.sentry.io/4505442332704768',
  tracesSampleRate: 1.0,
  environment,
  release,
});
